import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/Index'
import Home from '../views/Home.vue'
import Cookies from "js-cookie";

Vue.use(VueRouter)

const routes = [
    //公共布局下的路由
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '',
                redirect: 'home'
            },
            {
                path: 'home',
                component: Home
            },
            {
                path: 'video',
                component: () => import('../views/Video')
            },
            {
                path: 'me',
                component: () => import('../views/Me')
            },
            {
                path: '/recharge',
                name: 'recharge',
                component: () => import('../views/recharge')
            },
            {
                path: '/subscription',
                name: 'subscription',
                component: () => import('../views/subscription')
            },
        ]
    },
    {
        path: '/article/details/:id',
        name: 'ArticleDetails',
        component: () => import('../views/ArticleDetails')
    },
    {
        path: '/upload',
        name: 'Upload',
        component: () => import('../views/Upload')
    },
    {
        path: '/reply',
        name: 'reply',
        component: () => import('../views/reply')
    },
    {
        path: '/edit',
        name: 'edit',
        component: () => import('../views/edit')
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import('../views/Search')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login')
    },
    {
        path: '/collect',
        name: 'collect',
        component: () => import('../views/collect')
    },

    {
        path: '/myJob',
        name: 'myJob',
        component: () => import('../views/myJob')
    },
    {
        path: '/withdrawal',
        name: 'withdrawal',
        component: () => import('../views/withdrawal')
    },

    {
        path: '/editCard',
        name: 'editCard',
        component: () => import('../views/editCard')
    },
    {
        path: '/cardList',
        name: 'cardList',
        component: () => import('../views/cardList')
    },
    {
        path: '/bind',
        name: 'bind',
        component: () => import('../views/bind')
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/user')
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/detail')
    },
    {
        path: '/message',
        name: 'message',
        component: () => import('../views/message')
    },
    {
        path: '/verify',
        name: 'verify',
        component: () => import('../views/verify')
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//路由卫士
router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && !Cookies.get('accessToken')) next({name: 'Login'})
    else next()
})

export default router
