import {GetUserInfo} from "@/api/user";
/*import {
    getCategory
} from "@/api/user";*/
import Cookies from "js-cookie";
function setCookie(cname,cvalue,exdays)
{
    var d = new Date();
    d.setTime(d.getTime()+(exdays*24*60*60*1000));
    var expires = "expires="+d.toGMTString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}
export default {
  async updateUserInfo(ctx) {
     GetUserInfo({}).then((res)=>{
         if (res.code==0)
         {
             ctx.commit('setUserInfo', {
                 userInfo: res.result||{}
             })
             const user=JSON.stringify(res.result)
             setCookie('userInfo',user,365)
         }
         else {

         }
     })
  },
}

