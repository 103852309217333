<template>
  <div>
    <Header/>
    <router-view/>
    <div class="tabbar">
         <div @click="$router.push('/home')" class="tabbar-item" :class="$route.path=='/home'?'active':''">
            <div class="item-icon">
              <van-icon size="24" name="wap-home" />
            </div>
           <div class="item-text">
             首页
           </div>
         </div>
      <div @click="$router.push('/subscription')" class="tabbar-item" :class="$route.path=='/subscription'?'active':''">
        <div class="item-icon">
          <van-icon size="24" name="friends-o" />
        </div>
        <div class="item-text">
          关注
        </div>
      </div>
      <div @click="$router.push({path:'/upload?type=article'})" class="tabbar-item">
         <div class="plus">
           <van-icon size="24" color="#fff" name="plus" />
         </div>
      </div>
      <div class="tabbar-item" @click="$router.push('/recharge')" :class="$route.path=='/recharge'?'active':''">
        <div class="item-icon">
          <van-icon size="24" name="vip-card-o" />
        </div>
        <div class="item-text">
          会员
        </div>
      </div>
      <div @click="$router.push('/me')" class="tabbar-item" :class="$route.path=='/me'?'active':''">
        <div class="item-icon">
          <img
              v-if="user.headUrl"
              width="24"
              height="24"
              style="border-radius: 50%"
              v-lazyload :data-src="user.headUrl"
          />
          <van-icon v-else size="24" name="smile-o" />
        </div>
        <div class="item-text">
          我的
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/layout/Header";

export default {
  components: {Header},
  computed: {
    user: function () {
      return this.$store.state.userInfo || {}
    }
  },
  data() {
    return {
      active: 0,
      pages: [
        {
          icon: 'wap-home',
          title: '首页',
          path: '/home'
        },
        {
          icon: 'gold-coin',
          title: '充值',
          path: '/recharge'
        },

        {
          icon: 'smile',
          title: '我的',
          path: '/me'
        },
      ],
    };
  },

  mounted() {
    for (let i = 0; i < this.pages.length; i++) {
      if (this.$route.path === this.pages[i].path) this.active = i
    }
  },

  methods: {},

}
</script>

<style scoped>
.van-tabbar {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding-top: 3px;
  padding-bottom: 3px;
  z-index: 9999;
}
.tabbar{
  /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);*/
  padding-top: 18px;
  padding-bottom: 3px;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  box-sizing: content-box;
  width: 100%;
  height: 50px;
  background: url("../assets/tabar_bg2.png");
  background-position: top;
}
.tabbar-item{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #646566;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
}
.tabbar-item.active{
  color:rgb(47,107,219) ;
}
.plus{
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color:rgb(47,107,219);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.tabbar-item .item-icon{
  margin-bottom: 3px;
}
</style>
