import defaultImg from '@/assets/maskimg.jpg'
import loadingImg from '@/assets/loading_t.gif'
import {getCryptImg} from "@/api/page";
const defineDirective = Vue => {
    Vue.directive('lazyload', {
        inserted:(el, binding)=>{
            el.src =defaultImg
            const observer = new IntersectionObserver(([{ isIntersecting }]) => {
                //如果被监听的DOM元素进入了可视区
                if (isIntersecting) {
                    //取消监听
                    observer.unobserve(el)
                    //给DOM绑定一个事件,如果传过来的图片是个错误的地址,就把默认的图片给src赋值
                    el.onerror = () => {
                        el.src =''
                    }
                    //给图片赋值
                    getCryptImg({url:el.dataset.src}).then((res)=>{
                        if (res&&res.data)
                        {
                            el.src=res.data
                        }
                        else {
                        }
                    })
                }
            }, {
                threshold: 0.01
            })
            //监听DOM元素
            observer.observe(el)
        }
    })
}

export default defineDirective
