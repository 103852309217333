<template>
  <div>
    <div class="item-card" v-for="(item,index) in list" :key="index">

      <div v-if="item.jobDataType==1" class="item">
        <div @click="$router.push('/article/details/'+ item.id)">
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div style="display: flex">
              <div>
                <div class="author">
                  <img class="avatar" width="20"
                       height="20" v-lazyload :data-src="item.ext.headUrl" />
                  <span class="author-name">{{ item.nickname }}</span>
                  <van-icon v-if="item.ext.certification" color="#06f" style="padding-left: 7px;" name="wechat-pay"/>
                  <span class="author-des">{{ item.ext.nickName }}认证</span>
                </div>
                <div class="article">
                  {{ item.content }}
                </div>
              </div>
<!--              <div class="article-poster">
                <van-image
                    width="100px"
                    height="63px"
                    fit="contain"
                    v-loading="item.article.poster"
                />
              </div>-->
            </div>
            <div @click.stop class="star-bar">
              <span class="star-bar-count">
                <van-button @click.stop="agree(item)" size="mini" type="info">{{item.isZan?'':'赞同'}}<van-icon v-if="item.isZan" color="#1772f6" name="good-job" />   {{ item.clickCount }}</van-button> · {{ item.replyCount }}人接受挑战</span>
              <van-icon color="#909399" style="float: right;padding-top: 3px" name="ellipsis"/>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="item">
        <div @click="$router.push('/article/details/'+ item.id)">
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div style="display: flex">
              <div>
                <div class="author">
                  <img class="avatar" width="20"
                       height="20" v-lazyload :data-src="item.ext.headUrl" />
                  <span class="author-name">{{ item.nickname }}</span>
                  <van-icon v-if="item.ext.certification" color="#06f" style="padding-left: 7px;" name="wechat-pay"/>
                  <span class="author-des">{{ item.ext.nickName }}</span>
                </div>
                <div class="article">
                  {{ item.content }}
                </div>
              </div>
            </div>
            <div class="star-bar">
              <span class="star-bar-count">
                <van-button @click.native.stop="agree(item)" size="mini" type="info">
                <van-icon v-if="item.isZan" color="#1772f6" name="good-job" /> {{item.isZan?'':'赞同'}}{{ item.clickCount }}</van-button> · {{ item.replyCount }}人接受挑战</span>
              <van-icon color="#909399" style="float: right;padding-top: 3px" name="ellipsis"/>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {BASE_RUL} from "@/utils/request";
import {agree} from "@/api/article";
import {Toast} from "vant";

export default {
  name: "ArticleList",
  data() {
    return {
      base: BASE_RUL
    }
  },
  props: {
    list: {
      type: Array,
    }
  },
  methods:{
    agree(item) {
       console.log(item,'item')

      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      agree({id:item.id}).then((res) => {
        if (res.code == 0)
        {
          item.clickCount=item.clickCount+1
          this.$emit("updateList");
        }
          this.$toast.success("谢谢你的赞同")
      })
    },
  }
}
</script>

<style scoped>
.article-poster {
  min-width: 100px;
  min-height: 63px;
  border-radius: 5px;
  margin: 15px 0 0 10px;
  object-fit: fill;
}

.item {
  width: 100%;
  background: #ffffff;
  margin-bottom: 10px;
}

.content {
  padding: 10px;
}

.title {
  letter-spacing: 1px;
  font-weight: 500;
  color: rgba(0, 0, 0, .9);
}

.author {
  display: flex;
  padding-top: 10px;
}

.author-name {
  padding-left: 10px;
  font-size: 12px;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, .9);
}

.author-des {
  padding-left: 3px;
  font-size: 12px;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, .6);
}

.article {
  font-size: 14px;
  letter-spacing: 1px;
  padding: 5px 0;
  color: rgba(0, 0, 0, .8);
  max-height: 35px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.star-bar {
  height: 25px;
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.star-bar-count {
  font-size: 13px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
}
.author .avatar{
  border-radius: 10px;
}
.item-card{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.van-button--info{
  background: rgba(23,114,246,.1);
  border: none;
  color: #1772f6;
}
</style>
