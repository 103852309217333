<template>
  <div id="app" style="width: 100%;height: 100%">
    <router-view/>
  </div>
</template>
<script>
  import Cookies from 'js-cookie'
  export default{
    data() {
      return {}
    },
    methods: {

    },
    mounted() {
      if (Cookies.get('accessToken')) {
        this.$store.dispatch('updateUserInfo')
      }
    }
  }
</script>

<style>
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
