'use strict'

import axios from 'axios'
import md5 from 'js-md5';
import {Encrypt, Decrypt} from '../utils/crypto';
import {Base64} from 'js-base64';

const APPID = "E705D026E3B01F8E4BEC4EA9CC77F157";
import {baseUrl} from "./env";
import {Toast} from "vant";
import Cookies from "js-cookie";

// 创建签名
function createSign(query) {
    let sign = '';

    // 按参数字母名排序拼接字符串，最后加上 appId
    let urlParam = createLinkString(query) ;
    urlParam +=  APPID;

    // 拼接完的参数字符串转为 MD5
    sign = md5(urlParam).toUpperCase().substring(8,24);

    // 返回大写的签名
    console.log(sign,'sign')
    return sign;
}

/**
 * 将参数按字母名排序并拼接成字符串
 * @param {Map} params - 需要排序并参与字符拼接的参数 Map
 * @returns {string} 拼接后字符串
 */
function createLinkString(query) {
    const newkey = Object.keys(query).sort();
    let paramStr = ""
    for (let i = 0; i < newkey.length; i++) {
        const newArrKey = newkey[i];
        paramStr += newArrKey + "=" + query[newArrKey] + "&";
    }

    paramStr = paramStr.substring(0, paramStr.length - 1)
    return paramStr;
}

axios.interceptors.request.use(config => {
    // loading
    config.baseURL = baseUrl
    return config
}, error => {
    return Promise.reject(error)
})

axios.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.resolve(error.response)
})

function checkStatus(response) {
    // loading
    // 如果http状态码正常，则直接返回数据
    Toast.clear('all')
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
        if (response.data.code != 0) {
            if (response.data.message.indexOf('登录过期') != -1) {
                //  return
            }
            return {
                data: {
                    success: false,
                    error_msg: response.data.message
                }
            }
        } else {
        }
        if (response.data.result) {
            response.data.result = JSON.parse(Decrypt(response.data.result))
        }
        return response.data
        // 如果不需要除了data之外的数据，可以直接 return response.data
    }
    // 异常状态下，把错误信息返回去
    return {
        status: -404,
        msg: '网络异常'
    }
}

function checkCode(res) {
    // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
    if (res.status === -404) {
        Toast.fail({duration: 2000, message: res.msg});
    }
    if (res.data && (!res.data.success)) {
        // alert(res.data.error_msg)
        Toast.fail({duration: 2000, message: res.data.error_msg})
    }
    return res
}

export const post = function (url, data) {
    data.timeStamp= new Date().getTime()
    let sortString=createSign(data)
    let enData = Encrypt(JSON.stringify(data))
    return axios({
        method: 'post',
        url,
        data:{data:enData},
        timeout: 10000,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=UTF-8',
            'appId': APPID,
            'token':Cookies.get('accessToken'),
            'sign':sortString
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    ).then(
        (res) => {
            return checkCode(res)
        }
    )
}
export const postFile = function (url, data) {
    let dataObject={}
    dataObject.timeStamp= new Date().getTime()
    const  token=Encrypt(data.file.file.size)
    let sortString=createSign(dataObject)
    let formData = new FormData();
    formData.append('files', data.file.file)
    /*    let enData = Encrypt(JSON.stringify(data))
        data = {data:enData,timeStamp:new Date().getTime()};*/
    return axios({
        method: 'post',
        url,
        data: formData,
        timeout: 10000,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'multipart/form-data',
            'appId': APPID,
            'token':token,
            'sign':sortString
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    ).then(
        (res) => {
            return checkCode(res)
        }
    )
}
export const postFile2 = function (url, data) {
    let dataObject={}
    dataObject.timeStamp= new Date().getTime()
    console.log(data.file.size,'data.size',data)
    const  token=Encrypt(data.file.size)
    let sortString=createSign(dataObject)
    let formData = new FormData();
    formData.append('files', data.file)
    /*    let enData = Encrypt(JSON.stringify(data))
        data = {data:enData,timeStamp:new Date().getTime()};*/
    return axios({
        method: 'post',
        url,
        data: formData,
        timeout: 10000,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'multipart/form-data',
            'appId': APPID,
            'token':token,
            'sign':sortString
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    ).then(
        (res) => {
            return checkCode(res)
        }
    )
}
export const postVideo = function (url, data) {
    let dataObject={}
    dataObject.timeStamp= new Date().getTime()
    const  token=Encrypt(data.file.file.size)
    let sortString=createSign(dataObject)
    let formData = new FormData();
    formData.append('files', data.file.file)
    /*    let enData = Encrypt(JSON.stringify(data))
        data = {data:enData,timeStamp:new Date().getTime()};*/
    return axios({
        method: 'put',
        url,
        data: formData,
        timeout: 10000,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'Content-Type:application/octet-stream',
            'appId': APPID,
            'token':token,
            'sign':sortString
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    ).then(
        (res) => {
            return checkCode(res)
        }
    )
}
export const get = function (url, params) {
    let setHeader = {}
    if (url.substring(0, 5) != '/open') {
        setHeader.token = Cookies.get('accessToken')
    }
    return axios({
        method: 'get',
        url,
        params, // get 请求时带的参数
        timeout: 10000,
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    ).then(
        (res) => {
            return checkCode(res)
        }
    )
}
export const get2 = function (url, params) {
    let setHeader = {}
    if (url.substring(0, 5) != '/open') {
        setHeader.token = Cookies.get('accessToken')
    }
    return axios({
        method: 'get',
        url,
        params, // get 请求时带的参数
        timeout: 10000,
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }).then(
        (response) => {
            return response
        }
    ).then(
        (res) => {
            return res
        }
    )
}
export const getBase64 = function (url, params) {
    /* let decodedData =Base64.encode(params.url);*/
    return axios({
        method: 'get',
        url: params.url,
        timeout: 10000,
        responseType: 'arraybuffer',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    }).then(
        (response) => {
            if (response && response.data) {
                response.data = get_image(response.data)
            }
            return response
        }
    ).then(
        (res) => {
            return res
        }
    )
}

//参数排序
function objKeySort(arys) {
    //先用Object内置类的keys方法获取要排序对象的属性名数组，再利用Array的sort方法进行排序
    let newObj = "";
    if (arys === null) {
        return;
    }

    const newkey = Object.keys(arys).sort();
    for (let i = 0; i < newkey.length; i++) {
        const newArrKey = newkey[i];
        newObj += newArrKey + "=" + arys[newArrKey] + "&";
    }

    return newObj.substring(0, newObj.length - 1);
}

function get_image(buffer, urls) {
    var binary = '';
    var url = ''
    var bytes = new Uint8Array(buffer);
    for (var len = bytes.byteLength, i = 2; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    url = "data:image/png;base64," + Base64.btoa(binary);
    return url;
}
