import {post,get,getBase64,postFile,postVideo,get2,postFile2} from "@/config/http";

export function register(data){//
    return post(
        '/open/reg',
        data
    )
}
export function GetUserInfo(data){//获取用户信息
    return post(
        '/user/info',
        data
    )
}
export function Login(data){//
    return post(
        '/open/login',
        data
    )
}
export function uploadImg(data){//上传头像
    return postFile(
        '/upload/uploadImage',
        data
    )
}
export function uploadImg2(data){//上传头像
    return postFile2(
        '/upload/uploadImage',
        data
    )
}

export function getPostForm(data){//获取表单
    return post(
        '/user/job/form',
        data
    )
}
export function uploadVideo(data,url){//上传视频
    return postVideo(
        url,
        data
    )
}
export function getUploadUrl(data){//获取上传地址
    return post(
        '/user/getVideoUploadUrl',
        data
    )
}

export function editUser(data){//编辑个人信息
    return post(
        '/user/updateInfo',
        data
    )
}

export function getFaveList(data){//获取收藏列表
    return post(
        '/user/fav/list',
        data
    )
}

export function updateFave(data){//更新收藏
    return post(
        '/user/fav/update',
        data
    )
}

export function myJobList(data){//获取我的任务列表
    return post(
        '/user/jobList',
        data
    )
}

export function subscriptionList(data){//获取我的订阅列表
    return post(
        '/user/subscriptionRecords',
        data
    )
}

export function getVipList(data){//获取VIP列表
    return post(
        '/getVipItem',
        data
    )
}

export function getGoldList(data){//获取金币列表
    return post(
        '/getCoinItem',
        data
    )
}

export function orderSubmit(data){//提交订单
    return post(
        '/pay/toPay',
        data
    )
}

export function getChannelList(data){//获取支付通道
    return post(
        '/getChannelList',
        data
    )
}

export function getCardList(data){//获取银行卡列表
    return post(
        '/user/card/list',
        data
    )
}

export function editCard(data){//编辑银行卡
    return post(
        '/user/card/update',
        data
    )
}


export function sendCode(data){//发送验证码
    return post(
        '/sendCode',
        data
    )
}

export function cashWithdrawal(data){//提现
    return post(
        '/user/card/withdrawal',
        data
    )
}


export function bindMobile(data){//绑定手机
    return post(
        '/user/bindMobile',
        data
    )
}

export function getImgCode(data){//获取图片验证码
    return get2(
        '/open/randomImage/'+data,
        {}
    )
}


export function getUserInfomation(data){//获取用户资料
    return post(
        '/open/getUserInfo',
        data
    )
}

export function unlockReply(data){//解锁回复
    return post(
        '/user/answer/pay',
        data
    )
}

export function getReplyList(data){//获取我的回复
    return post(
        '/user/answerList',
        data
    )
}


export function getUserReplyList(data){//获取我的回复
    return post(
        '/user/answerList/others',
        data
    )
}

export function subscriptionUser(data){//订阅用户
    return post(
        '/user/subscription',
        data
    )
}

export function getNoticeList(data){//我的通知
    return post(
        '/user/notices/list',
        data
    )
}

export function verifyUser(data){//验证
    return post(
        '/user/certification/apply',
        data
    )
}

export function getUserTotal(data){//获取用户统计
    return post(
        '/user/userCount',
        data
    )
}


