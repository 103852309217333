<template>
  <div class="main">
    <van-tabs v-model="active" @click="onClick" @change="onClick">
      <van-tab v-for="(item,index) in tagList" :key="index" :title="item.title" style="background: #f5f6f7">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div style="margin-bottom: 66px;margin-top: 15px">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
              <ArticleList @updateList="onRefresh" :list="articleList"/>
            </van-list>
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import ArticleList from "@/components/ArticleList";
import {getAllTask, getCategoryList, getRecommendList, getHotList, taskListByCate} from "@/api/article";
import {Toast} from "vant";

export default {

  components: {ArticleList},

  data() {
    return {
      active: 0,
      articleList: [],
      tagList: [],
      isLoading: false,
      loading: false,
      finished: false,
      page: 1,
      total: 1
    }
  },

  mounted() {
    /* getAllTask({
       "page": "1",
       "limit": "10"
     }).then((res) => {
       if (res.code == 0) {
         this.articleList = res.result.records || []
       }
     })*/
    getCategoryList({
      "id": "10000",
      "page": this.page,
      "limit": "2"
    }).then((res) => {
      if (res.code == 0) {
        this.tagList = [{
          "imgUrl": "",
          "note": "",
          "createTime": "",
          "id": "11",
          "sort": 2,
          "title": "首页推荐",
          "status": 1
        }, {
          "imgUrl": "",
          "note": "",
          "createTime": "",
          "id": "22",
          "sort": 2,
          "title": "热门",
          "status": 1
        }].concat(res.result)
      }
    })
    /*this.getHomeRecommend()*/
  },

  methods: {

    onClick(e) {
      this.articleList = []
      this.isLoading = false;
      this.page = 1
      this.total = 0
      if (this.active == 0) {
        this.getHomeRecommend()
      } else if (this.active == 1) {
        this.getHomeHot()
      } else {
        this.getTaskByCate()
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
    },

    onRefresh() {
      this.$toast('刷新成功');
      this.articleList = []
      this.isLoading = false;
      this.page = 1
      this.total = 0
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      if (this.active == 0) {
        this.getHomeRecommend()
      } else if (this.active == 1) {
        this.getHomeHot()
      } else {
        this.getTaskByCate()
      }
    },
    getHomeRecommend() {//获取首页推荐
      getRecommendList({
        "page": this.page,
        "limit": "2"
      }).then((res) => {
        if (res.code == 0) {
          this.articleList = this.articleList.concat(res.result.records || [])
          this.total = res.result.total
        }
        this.isLoading = false;
        this.loading = false;
        if (this.articleList.length >= 4) {
          this.finished = true;
        } else {

        }
      })
    },
    getHomeHot() {//获取首页热门
      getHotList({
        "page": this.page,
        "limit": "2"
      }).then((res) => {
        if (res.code == 0) {
          this.articleList = res.result.records
        }
      })
    },
    getTaskByCate() {
      taskListByCate({
        categoryId: this.tagList[this.active - 1].id, "page": this.page,
        "limit": "2"
      }).then((res) => {
        if (res.code == 0) {
          this.articleList = res.result.records
        }
      })
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      this.page += 1
      if (this.active == 0) {
        this.getHomeRecommend()
      } else if (this.active == 1) {
        this.getHomeHot()
      } else {
        this.getTaskByCate()
      }
    },

  },

}
</script>

<style scoped>
.main {
  width: 100%;
  height: 90vh;
  background: #f5f6f7 !important;
}

>>> .van-tabs__line {
  width: 20px;
  background-color: #0066ff;
}

.d {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, .5);
  text-align: center;
}
</style>
