import service from "../utils/request"
import {post,get,getBase64} from "@/config/http";

export function saveArticle(data){//
    console.log(data,'79ad9sasd')
    return post(
        '/user/job/create',
        data
    )
}
export function FindAllType(data){//
    return get(
        '/article/type/all',
        data
    )
}
export function getAllTask(data){
    return post(
        '/job/index',
        data
    )
}
export function findArticleById(data){//获取任务详情
    return post(
        '/job/info',
        data
    )
}
export function SearchArticle(data){//
    return get(
        '/article/search/' + data,
        data
    )
}
export function agree(data){//
    return post(
        '/user/updateZan',
        data
    )
}
export function getCategoryList(data){//
    return post(
        '/categoryList',
        data
    )
}
export function getRecommendList(data){//获取首页推荐
    return post(
        '/job/index',
        data
    )
}
export function getHotList(data){//获取首页热门
    return post(
        '/job/hot',
        data
    )
}
export function taskListByCate(data){//通过分类id获取任务
    console.log(data,'8a08dqe')
    return post(
        '/job/list',
        data
    )
}
export function getTaskInfo(data){//通过分类id获取任务
    console.log(data,'8a80d8ad')
    return post(
        '/answer/info',
        data
    )
}
